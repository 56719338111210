@import '../../mixins/link';
@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/truncate';

.info {
  text-align: left;
}

.info-title {
  @include heading(large);
  margin-top: 0.5rem;
}

.title-rating {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  align-items: flex-start;
}

.info-author {
  @include copy();
  @include truncate(3);
  margin-bottom: 0.5rem;
}

.info-price {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-box {
    margin-bottom: map-get($map: $spacing, $key: sitePadding);

    @include breakpoint(large, min) {
      margin-bottom: 1rem;
    }
  }

  &-text {
    @include copy(jumbo);
    color: map-get($map: $colors, $key: primary);

    span {
      @include type($font: copy, $size: medium, $color: copy);
      text-decoration: line-through;
      margin-left: 0.25rem;
    }
  }
}

.info-actions {
  display: flex-cols;
  align-items: stretch;
  justify-content: center;

  button {
    &:last-of-type {
      display: flex;
      margin-top: 0.5rem;
    }
  }
}

.admin-btn {
  margin-top: 1rem;
}

.by-text {
  color: map-get($map: $colors, $key: copyLight);
}

.author-text {
  &:hover {
    color: map-get($map: $colors, $key: primary);
  }
}

.modal {
  &-container {
    text-align: center;
  }

  &-title {
    @include type($font: heading, $size: jumbo);
    margin-bottom: 1rem;
  }

  &-message {
    @include copy();
    margin-bottom: 1rem;
  }

  &-spacer {
    height: 1rem;
  }
}