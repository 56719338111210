@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';
@import '../../mixins/truncate';

.title-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  h2 {
    @include heading(base);
    flex: 1;
    min-width: 100%;
    margin-bottom: 0.5rem;
  }

  button {
    width: auto;
  }

  @include breakpoint(small, max) {
    &-link {
      margin-top: 0.5rem;
      min-width: 100%;
      flex: 1;

      button {
        min-width: 100%;
        margin: 0;
      }
    }
  }

  &-image {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    width: 4rem;
    margin-right: 1.5rem;

    img {
      border-radius: map-get($map: $spacing, $key: borderRadius) / 1.5;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-self: flex-start;
    flex: 1;
    margin-right: map-get($map: $spacing, $key: sitePadding);

    h2 {
      @include type($font: copyBold);
      transition: all 0.25s;
      margin: 0;

      &:hover {
        @include link();
      }
    }

    h3 {
      @include type($font: copy, $size: tiny);
      margin-bottom: 0.25rem;

      span {
        display: inline-block;
        color: map-get($map: $colors, $key: copyLight);
        margin-right: 0.25rem;
      }
    }

    p {
      @include type($font: copy, $size: tiny, $color: copy);
    }
  }
}
